<template>
  <div>
    <h4 class="card-label border-bottom mb-3">POS Management</h4>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              v-if="permission.is_add == 1"
              @click="addPOS"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
              >Add POS User
            </b-button>
            <b-modal
              hide-footer
              id="modal-standard"
              :title="pos_title"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <div>
                  <b-form-group
                    label="Name"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                  >
                    <b-form-input
                      type="text"
                      id="name-input"
                      v-model="name"
                      class="input-style"
                      placeholder="Enter Name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group label="Type">
                  <div class="d-flex justify-content-start align-items-center">
                    <b-form-radio
                      v-model="add_type"
                      name="add_type-radios"
                      value="1"
                      >PIN Login
                    </b-form-radio>
                    <b-form-radio
                      v-model="add_type"
                      name="add_type-radios"
                      value="0"
                      class="ml-3"
                      >Email Login
                    </b-form-radio>
                  </div>
                </b-form-group>
                <div v-if="add_type === pin_type">
                  <b-form-group
                    label="PIN"
                    label-for="quantity-input"
                    invalid-feedback="Quantity is required"
                  >
                    <b-form-input
                      type="number"
                      id="pin_code-input"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      :regExp="setting.regExp"
                      maxlength="4"
                      v-model="pin_code"
                      class="input-style"
                      placeholder="Enter PIN"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="add_type === email_type">
                  <b-form-group
                    label="Email"
                    label-for="email-input"
                    invalid-feedback="Email is required"
                  >
                    <b-form-input
                      type="email"
                      id="email-input"
                      v-model="email"
                      class="input-style"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="add_type === email_type">
                  <b-form-group
                    label="Password"
                    label-for="password-input"
                    invalid-feedback="Password is required"
                  >
                    <b-form-input
                      type="password"
                      id="password-input"
                      v-model="password"
                      class="input-style"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="m-t-15">
                  <b-button
                    type="button"
                    @click="handleOk"
                    class="mt-3 btn-orange pull-right"
                    :disabled="isFormValid"
                  >
                    Add
                  </b-button>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport(csvData)">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="users"
            :fields="fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(staff_role_id)="users">
              <b-badge
                v-if="users.item.staff_role_id === 1"
                class="mr-1 badge-font badge-success cursor-pointer"
                pill
                variant="success"
                >PIN User
              </b-badge>
              <b-badge
                v-if="users.item.staff_role_id === 5"
                class="mr-1 badge-font badge-success cursor-pointer"
                pill
                variant="success"
                >Main Login User
              </b-badge>
            </template>
            <template v-slot:cell(action)="users">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteStaff(users)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import helpers from "../../helpers";
import axios from "axios";

export default {
  name: "RestaurantSettings",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      users: [],
      restaurantUuid: "",
      fields: [
        { key: "user.name", label: "User Name", sortable: true },
        { key: "user.pin_code", label: "Pin Code", sortable: true },
        { key: "user.email", label: "User Email", sortable: true },
        { key: "staff_role_id", label: "Type", sortable: true },
        { key: "action", label: "Action", sortable: true }
      ],
      name: "",
      email: "",
      password: "",
      pin_code: "",
      add_type: "",
      email_type: "0",
      pin_type: "1",
      is_edit: false,
      pos_title: "Add POS",
      setting: {
        regExp: /^[0\D]*|\D*/g, // Match any character that doesn't belong to the positive integer
        // regExp: /^[D]*|\D*/g|/^[1-9]\d{0,2}(?:\,\d{1,3})?$/,
        replacement: "",
        val: "0"
      }
    };
  },
  components: {},
  computed: {
    rows() {
      return this.users.length;
    },
    isFormValid() {
      return !(this.add_type !== "" && this.name !== "");
    }
  },

  methods: {
    addPOS() {
      this.name = "";
      this.email = "";
      this.password = "";
      this.pos_title = "Add POS";
      this.this.pin_code = "";
    },
    resetModal() {
      this.name = "";
      this.email = "";
      this.password = "";
      this.add_type = "";
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.add_type === this.pin_type) {
        if (this.pin_code.length !== 4) {
          helpers.errorMessage(this, "Pin should of 4 digits!");
          return;
        }
      }
      let obj = {
        add_type: parseInt(this.add_type) ? true : false,
        name: this.name,
        pin_code: this.pin_code,
        email: this.email,
        password: this.password
      };

      ApiService.post(
        this.$path + "/admin/pos/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          this.users.unshift(response.data.data);
          helpers.successMessages(this, response.data.message);
          this.handleSubmit();
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    handleSubmit() {
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard");
      });
    },
    getPOSUsers() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/pos/restaurant/" + this.restaurantUuid)
        .then(response => {
          this.users = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteStaff(staff) {
      this.loader = true;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete(
            this.$path +
              "/admin/pos/restaurant/" +
              this.restaurantUuid +
              "/" +
              staff.item.uuid
          )
            .then(response => {
              helpers.successMessages(this, response.data.message);
              let index = this.users.findIndex(
                x => x.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.users.splice(index, 1);
              }
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    }
  },
  watch: {
    add_type(val) {
      if (val === this.pin_type) {
        this.email = "";
        this.password = "";
      }
      if (val === this.email_type) {
        this.pin_code = "";
      }
    }
  },
  mounted() {
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantUuid = this.restaurantData.uuid;
    this.getPOSUsers();
    this.totalRows = this.users.length;
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  }
};
</script>
