<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Staff List</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              v-if="permission.is_add == 1"
              @click="addStaff"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
              >Add Staff
            </b-button>
            <b-modal
              hide-footer
              id="modal-standard"
              :title="staff_title"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="form-group">
                  <label>Select Staff Role</label>
                  <div>
                    <b-form-select
                      v-model="staff_role"
                      :options="staff_roles"
                      class=""
                      value-field="value"
                      text-field="text"
                    ></b-form-select>
                  </div>
                </div>
                <div v-if="staff_role === 4" class="form-group">
                  <label>Web access</label>
                  <div>
                    <b-form-select
                      v-model="web_access"
                      :options="web_access_options"
                      class=""
                      value-field="value"
                      text-field="text"
                    ></b-form-select>
                  </div>
                </div>
                <div
                  v-if="staff_role == 4 && web_access == 1"
                  class="form-group"
                >
                  <b-form-group label="Roles">
                    <div class="d-flex justify-content-start">
                      <b-form-checkbox
                        v-model="roles.add"
                        :checked="roles.add"
                        name="some-radios"
                      >
                        Add
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="roles.edit"
                        :checked="roles.edit"
                        name="some-radios"
                        class="ml-3"
                      >
                        Edit
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="roles.delete"
                        :checked="roles.delete"
                        name="some-radios"
                        class="ml-3"
                      >
                        Delete
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Name"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                >
                  <b-form-input
                    id="name-input"
                    placeholder="Name"
                    class="input-style"
                    v-model="staff_name"
                    required
                  ></b-form-input>
                </b-form-group>
                <div v-if="staff_role == 4" class="form-group">
                  <div class="form-group">
                    <label>Email</label>
                    <div>
                      <input
                        type="email"
                        placeholder="yourname@gmail.com"
                        v-model="staff_email"
                        class="form-control input-style"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="staff_role == 4 && web_access == 1"
                  class="form-group"
                >
                  <div class="form-group">
                    <label>Password</label>
                    <div>
                      <input
                        type="password"
                        placeholder="Password"
                        v-model="staff_password"
                        class="form-control input-style"
                      />
                    </div>
                  </div>
                </div>
                <b-form-group
                  label="Contact"
                  label-for="contact-input"
                  invalid-feedback="Contact is required"
                >
                  <b-form-input
                    type="number"
                    min="1"
                    id="contact-input"
                    class="input-style"
                    v-model="staff_contact"
                  ></b-form-input>
                </b-form-group>
                <div class="m-t-15">
                  <b-button
                    type="button"
                    @click="handleOk"
                    class="mt-3 btn-orange pull-right"
                    :disabled="isFormValid"
                  >
                    Add
                  </b-button>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div class="dataTables_filter text-md-right filter-dataTables">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport()">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="staff"
            :fields="staff_fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(action)="staff">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editModal(staff)"
                    v-b-modal.modal-standard
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteStaff(staff)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import helpers from "../../helpers";
import axios from "axios";

export default {
  name: "RestaurantSettings",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      roles: { add: false, edit: false, delete: false },
      web_access: 0,
      web_access_options: [
        { text: "No", value: 0 },
        { text: "Yes", value: 1 }
      ],
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      staff: [],
      staff_roles: [],
      staff_role_title: "",
      staff_title: "",
      restaurantId: this.$route.params.restaurant,
      restaurantUuid: this.$route.params.restaurant_uuid,
      staff_fields: [
        { key: "user.name", label: "Name", sortable: true },
        { key: "user.mobile_number", label: "Contact", sortable: true },
        { key: "user.email", label: "Email", sortable: true },
        { key: "user.pin_code", label: "Pin Code", sortable: true },
        { key: "staff_role_title", label: "Type", sortable: true },
        { key: "action" }
      ],
      staff_role: "",
      staff_name: "",
      staff_email: "",
      staff_password: "",
      staff_contact: "",
      kitchens: [],
      tables: [],
      kitchen_id: "",
      nameState: null,
      contactState: null,
      DropDownMenu: false,
      uuid: "",
      is_edit: false,
      staff_uuid: "",
      staff_user_id: ""
    };
  },
  components: {},
  computed: {
    rows() {
      return this.staff.length;
    },
    csvData() {
      return this.staff.map(item => ({
        ...item
      }));
    },
    isFormValid() {
      return !(this.staff_role !== "" && this.staff_name !== "");
    }
  },
  methods: {
    getRestaurantTables() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/table/restaurant/" + this.restaurantUuid)
        .then(response => {
          this.tables = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    editModal(staff) {
      this.is_edit = true;
      this.staff_title = "Edit Staff";
      let index = this.staff_roles.findIndex(
        x => x.value === staff.item.staff_role_id
      );
      if (index !== -1) {
        this.staff_role = staff.item.staff_role_id;
      }
      this.staff_name = staff.item.user.name;
      this.staff_contact = staff.item.user.mobile_number;
      this.staff_email = staff.item.user.email;
      this.staff_uuid = staff.item.uuid;
      this.nameState = null;
      this.contactState = null;
      if (this.staff_role === 4) {
        this.roles = {
          add: staff.item.permission ? !!staff.item.permission.is_add : false,
          edit: staff.item.permission ? !!staff.item.permission.is_edit : false,
          delete: staff.item.permission
            ? !!staff.item.permission.is_deleted
            : false
        };
        this.web_access = staff.item.permission
          ? staff.item.permission.web_access_enabled
          : 0;
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      this.contactState = valid;
      return valid;
    },
    addStaff() {
      this.staff_title = "Add Staff";
      this.staff_role = "";
      this.staff_name = "";
      this.staff_email = "";
      this.staff_password = "";
      this.roles = {};
      this.staff_contact = "";
      this.kitchen_id = "";
      this.nameState = null;
      this.contactState = null;
      this.is_edit = false;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      this.loader = true;
      bvModalEvt.preventDefault();
      // Trigger submit handler
      let obj = {
        staff_role: this.staff_role,
        name: this.staff_name,
        mobile_number: this.staff_contact
      };

      let source = {
        email: this.staff_email,
        web_access_enabled: this.web_access !== 0,
        password: this.staff_password,
        is_add: this.roles.add,
        is_edit: this.roles.edit,
        is_deleted: this.roles.delete
      };
      if (this.staff_role === 4) {
        Object.assign(obj, source);
      }

      this.is_edit ? this.updateStaffApi(obj) : this.addStaffApi(obj);

      // Prevent modal from closing
    },
    addStaffApi(obj) {
      ApiService.post(
        this.$path + "/admin/staff/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          this.loader = false;
          helpers.successMessages(this, response.data.message);
          this.staff.unshift(response.data.data);
          this.handleSubmit();
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateStaffApi(obj) {
      ApiService.patch(
        this.$path +
          "/admin/staff/restaurant/" +
          this.restaurantUuid +
          "/" +
          this.staff_uuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          let index = this.staff.findIndex(
            x => x.uuid === response.data.data.uuid
          );
          if (index !== -1) {
            this.staff[index].user = response.data.data.user;
            this.staff[index].staff_role_title =
              response.data.data.staff_role_title;
            if (response.data.data.permission) {
              this.staff[index].permission = response.data.data.permission;
            }
          }
          this.staff_role = "";
          this.staff_name = "";
          this.staff_email = "";
          this.staff_password = "";
          this.staff_contact = "";
          this.nameState = null;
          this.contactState = null;
          this.is_edit = false;
          this.loader = false;
          this.handleSubmit();
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard");
      });
    },
    getRestaurantStaff() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/staff/restaurant/" + this.restaurantUuid)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.loader = false;
          this.staff = response.data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getStaffRoles() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/staff/roles")
        .then(response => {
          response.data.data.map(x => {
            this.staff_roles.push({ text: x.title, value: x.id });
          });
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    deleteStaff(staff) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path +
              "/admin/staff/restaurant/" +
              this.restaurantUuid +
              "/" +
              staff.item.uuid
          )
            .then(response => {
              helpers.successMessages(this, response.data.message);
              let index = this.staff.findIndex(
                x => x.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.staff.splice(index, 1);
              }
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Staff.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.staff);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Staff.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantId = this.restaurantData.id;
    this.restaurantUuid = this.restaurantData.uuid;
    this.totalRows = this.staff.length;
    this.getRestaurantStaff();
    this.getStaffRoles();
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  },
  watch: {}
};
</script>
<style scoped></style>
