<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Restaurant Tablet</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              v-if="permission.is_add == 1"
              @click="addKitchen"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
              >Add Tablet
            </b-button>
            <b-modal
              hide-footer
              :title="kitchen_title"
              id="modal-standard"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                  :state="nameState"
                  label="Kitchen Name"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                >
                  <b-form-input
                    id="name-input"
                    v-model="kitchen_name"
                    :state="nameState"
                    required
                    class="input-style"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Description" label-for="comment-input">
                  <b-form-textarea
                    id="description-input"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>
                <b-button
                  class="mt-3 btn-orange pull-right"
                  type="button"
                  :disabled="isFormValid"
                  @click="handleOk()"
                >
                  Add
                </b-button>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport(csvData)">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="kitchen"
            :fields="fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(action)="kitchen">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    v-if="permission.is_edit == 1"
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editModal(kitchen)"
                    v-b-modal.modal-standard
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    v-if="permission.is_deleted == 1"
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteKitchen(kitchen)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import helpers from "../../helpers";
import axios from "axios";

export default {
  name: "RestaurantSettings",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      loader: true,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      kitchen: [],
      restaurantId: "",
      restaurantUuid: "",
      DropDownMenu: false,
      uuid: "",
      dropDownListCheck: false,
      fields: [
        { key: "kitchen_name", label: "Kitchen Name", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "pin_code", label: "Pin Code", sortable: true },
        { key: "action" }
      ],
      kitchen_name: "",
      description: "",
      nameState: null,
      ipState: null,
      descState: null,
      is_edit: false,
      kitchen_title: "Add Tablet",
      kitchen_uuid: ""
    };
  },
  components: {},
  computed: {
    rows() {
      return this.kitchen.length;
    },
    csvData() {
      return this.kitchen.map(item => ({
        kitchen_name: item.kitchen_name,
        description: item.description
      }));
    },
    isFormValid() {
      return !(this.kitchen_name !== "");
    }
  },

  methods: {
    addKitchen() {
      this.kitchen_name = "";
      this.description = "";
      this.nameState = null;
      this.ipState = null;
      this.descState = null;
      this.is_edit = false;
      this.kitchen_title = "Add Tablet";
    },
    resetModal() {},
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    handleOk() {
      this.loader = true;
      let obj = {
        kitchen_name: this.kitchen_name,
        description: this.description
      };
      this.is_edit ? this.updateKitchenApi(obj) : this.addKitchenApi(obj);
    },
    addKitchenApi(obj) {
      ApiService.post(
        this.$path + "/admin/kitchen/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, "Tablet Added Successfully!");
          this.kitchen.unshift(response.data.data);
          this.$bvModal.hide("modal-standard");
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateKitchenApi(obj) {
      ApiService.patch(
        this.$path +
          "/admin/kitchen/restaurant/" +
          this.restaurantUuid +
          "/" +
          this.kitchen_uuid,
        obj
      )
        .then(response => {
          let index = this.kitchen.findIndex(
            x => x.uuid === response.data.data.uuid
          );
          if (index !== -1) {
            this.kitchen[index].kitchen_name = response.data.data.kitchen_name;
            this.kitchen[index].description = response.data.data.description;
            this.kitchen[index].pin_code = response.data.data.pin_code;
          }
          helpers.successMessages(this, "Tablet Updated Successfully!");
          this.loader = false;
          this.$bvModal.hide("modal-standard");
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
      this.$bvModal.hide("modal-standard");
    },

    getRestaurantKitchens() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/kitchen/restaurant/" + this.restaurantUuid)
        .then(response => {
          this.kitchen = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteKitchen(kitchen) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path +
              "/admin/kitchen/restaurant/" +
              this.restaurantUuid +
              "/" +
              kitchen.item.uuid
          )
            .then(response => {
              helpers.successMessages(this, "Tablet Delete Successfully!");
              let index = this.kitchen.findIndex(
                x => x.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.kitchen.splice(index, 1);
              }
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
        this.loader = false;
      });
    },
    editModal(kitchen) {
      this.nameState = null;
      this.descState = null;
      this.is_edit = true;
      this.kitchen_title = "Edit Tablet";
      this.kitchen_name = kitchen.item.kitchen_name;
      this.description = kitchen.item.description;
      this.kitchen_uuid = kitchen.item.uuid;
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Kitchen.csv");
      link.click();
    },
    excelExport(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Kitchen.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantId = this.restaurantData.id;
    this.restaurantUuid = this.restaurantData.uuid;
    this.getRestaurantKitchens();
    this.totalRows = this.kitchen.length;
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
