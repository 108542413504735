<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Management Settings'">
        <template v-slot:preview>
          <div class="card card-custom no-box-shadow">
            <div class="card-body resturant-setting-vue p-0">
              <div
                class="d-flex flex-wrap justify-content-start restaurant-setting-menu"
              >
                <div v-for="item in menu" :key="item.id">
                  <button
                    :class="item.class"
                    @click="updateClass(item.id, item.path)"
                  >
                    {{ item.title }}
                  </button>
                </div>
                <div class="w-100 mt-4">
                  <KitchenManagement
                    v-if="type === '/kitchen_management'"
                  ></KitchenManagement>
                  <StaffManagement
                    v-if="type === '/staff_management'"
                  ></StaffManagement>
                  <TablesManagement
                    v-if="type === '/tables_management'"
                  ></TablesManagement>
                  <POSManagement
                    v-if="type === '/pos_management'"
                  ></POSManagement>
                  <PrinterManagement
                    v-if="type === '/printer_management'"
                  ></PrinterManagement>
                </div>
              </div>
            </div>
          </div>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import KitchenManagement from "./KitchenManagement";
import StaffManagement from "./StaffManagement";
import TablesManagement from "./TablesManagement";
import POSManagement from "./POSManagement";
import PrinterManagement from "./PrinterManagement";
// import ApiService from "@/core/services/api.service";
// import Swal from "sweetalert2";
// import XLSX from "xlsx";

export default {
  name: "Management",
  data() {
    return {
      type: "/kitchen_management",
      menu: [
        {
          id: 1,
          path: "/kitchen_management",
          title: "Kitchen Management",
          class: "btn btn--menu active"
        },
        {
          id: 2,
          path: "/staff_management",
          title: "Staff Management",
          class: "btn btn--menu"
        },
        {
          id: 3,
          path: "/tables_management",
          title: "Table Management",
          class: "btn btn--menu"
        },
        {
          id: 4,
          path: "/pos_management",
          title: "POS Management",
          class: "btn btn--menu"
        },
        {
          id: 5,
          path: "/printer_management",
          title: "Printer Management",
          class: "btn btn--menu"
        }
      ]
    };
  },
  components: {
    KTCodePreview,
    KitchenManagement,
    StaffManagement,
    TablesManagement,
    POSManagement,
    PrinterManagement
  },
  computed: {},
  methods: {
    updateClass(id, path) {
      for (let i = 0; i < this.menu.length; i++) {
        this.menu[i].class = "btn btn--menu";
        if (this.menu[i].id === id) {
          this.menu[i].class = "btn btn--menu active";
          // console.log(path);
          this.type = path;
          // router.push({path: path});
        }
      }
    }
  },
  mounted() {}
};
</script>
